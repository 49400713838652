import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FiCalendar, FiUpload } from "react-icons/fi";

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import TimezoneSelect from 'react-timezone-select'

import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { fetchData, fetchDataPrimereact, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import { useDispatch, useSelector } from 'react-redux';
import { onCreateCampaign } from '../../../../Redux/Actions/AgentActions';
import { useLocation } from 'react-router-dom';
import excelFile from "../../../../images/test.csv"
import { Tooltip } from 'react-tooltip';
import { setAlert } from '../../../../Redux/Actions/AlertActions';

const CreateCampaignModal = ({ show, handleClose, fetchCampaigns, agents, agentId }) => {
    let date = new Date()
    const dispatch = useDispatch()
    const location = useLocation()
    const agent = useSelector(state => state.agent.data)
    const [percent, setPercent] = useState(0)

    const [loader, setLoader] = useState({
        save: false,
        upload: false
    })
    const [data, setData] = useState({
        name: "",
        campaignType: "outbound",
        startDate: "",
        endDate: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dailyBudget: "",
        url: "",
        agentId: ""
    })

    const handleChange = (e) => {
        let { name, value } = e.target
        // if (name === "dailyBudget") {
        //     value = Math.max(0, Math.min(100, Number(value)));
        // }
        if (name === "dailyBudget") {
            let parsedValue = Number(value);

            if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
                value = parsedValue;
            } else {
                value = data[name] || 0;
            }
        }
        setData({
            ...data,
            [name]: value
        })
    }
    const handleChangeTimezone = (e) => {
        let obj = {
            target: {
                name: "timezone",
                value: e.value
            }
        }
        handleChange(obj)
    }

    const onCompeleteUpload = (url) => {
        let obj = {
            target: {
                name: "url",
                value: url
            }
        }
        handleChange(obj)
    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (["text/csv"].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 300000000) {
                    formData.append("upload_type", "csv")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "csv"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 300MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...data }


        if (location.pathname === "/agent/customize-agent") {
            obj.campaignType = agent.agentType
            obj.agentId = agentId
        } else {
            obj.agentId = obj.agentId.id
        }
        obj.startDate = obj.startDate.toUTCString()
        obj.endDate = obj.endDate.toUTCString()

        if (obj.campaignType === "inbound") {
            obj.url = ""
            setLoader({
                ...loader,
                save: true
            })
            dispatch(onCreateCampaign(obj, fetchCampaigns, setData, handleClose, loader, setLoader))
        } else {
            if (obj.url === "") {
                dispatch(setAlert("Please upload a contact list to continue!", "danger"))
            } else {
                setLoader({
                    ...loader,
                    save: true
                })
                dispatch(onCreateCampaign(obj, fetchCampaigns, setData, handleClose, loader, setLoader))
            }
        }

    }



    return (
        <Modal className="theme-modal" show={show} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">Creating your own campaign is just one step away</h3>
                    <p className="text-center pt-2">Don't worry, you can obviously always later change the information given</p>
                    <div className="modal-card mt-4">
                        <h6>Campaign Information</h6>
                        <div className="input-wrap">
                            <label className="label" htmlFor="" data-tooltip-id="my-tooltip"
                                data-tooltip-content="Enter a unique name for your campaign. This will help you identify and track the campaign's performance in reports.">Campaign Name</label>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                placeholder="Summer Flash Sale Campaign January - 2024"
                                value={data.name}
                                onChange={handleChange}
                                required
                            />

                        </div>
                        {/* {location.pathname === "/agent/customize-agent" ? null :
                            <div className="select-group">
                                <ul>
                                    <li>
                                        <div className="select-group-single">
                                            <span className="select-check"><AiFillCheckCircle /></span>
                                            <h6>Inbound Campaign</h6>
                                            <p>An inbound AI calling agent will answer a call that is typically initiated by a customer to a call center or contact center i.e. the AI agent itself. The AI calling agent will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                            <input
                                                type="radio"
                                                name="campaignType"
                                                checked={data.campaignType === "inbound" ? true : false}
                                                onChange={handleChange}
                                                value={"inbound"}
                                                required
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="select-group-single">
                                            <span className="select-check"><AiFillCheckCircle /></span>
                                            <h6>Outbound Campaign</h6>
                                            <p>An outbound AI calling agent is one that makes an outgoing call to a prospect to generate their interest in a company's product or service — or close a sale. The AI agent who makes the outbound calls are usually sales or customer service representatives type and will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                            <input
                                                type="radio"
                                                name="campaignType"
                                                checked={data.campaignType === "outbound" ? true : false}
                                                onChange={handleChange}
                                                value={"outbound"}
                                                required
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>} */}
                        {location.pathname === "/agent/customize-agent" ? null :
                            <div className="input-wrap">
                                <label className="label" htmlFor=""
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Choose the AI agent that will be assigned to this campaign. The agent will handle all interactions associated with this campaign"
                                >Select Agent</label>
                                <Dropdown
                                    value={data.agentId}
                                    onChange={(e) => handleChange(e)}
                                    options={agents.filter((curElem) => {
                                        return curElem.agentType === data.campaignType
                                    })}
                                    optionLabel='name'
                                    placeholder="Select Agent"
                                    className="input"
                                    name="agentId"
                                    required
                                />

                            </div>
                        }
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Set the date when this campaign will begin. The AI agent will start handling calls from this date onwards"
                                    >Start Date</label>
                                    <div className="dateInp">
                                        <span className="calendar-icon"><FiCalendar /></span>
                                        <Calendar
                                            className="input"
                                            onChange={handleChange}
                                            showTime
                                            hourFormat="12"
                                            value={data.startDate}
                                            name='startDate'
                                            placeholder='Enter Start Date'
                                            minDate={date}
                                            required
                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Specify the date when this campaign will end. The AI agent will stop handling calls after this date"
                                    >End Date</label>
                                    <div className="dateInp">
                                        <span className="calendar-icon"><FiCalendar /></span>
                                        <Calendar
                                            className="input"
                                            onChange={handleChange}
                                            showTime
                                            value={data.endDate}
                                            hourFormat="12"
                                            name='endDate'
                                            placeholder='Enter End Date'
                                            minDate={data.startDate}
                                            disabled={data.startDate ? false : true}
                                            required
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Select the timezone in which the campaign will operate. This ensures that the campaign's start and end times align with the intended time zone"
                                    >Timezone</label>
                                    <TimezoneSelect
                                        value={data.timezone}
                                        onChange={(e) => handleChangeTimezone(e)}
                                        className="input"
                                        required
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Enter the maximum amount you want to spend on this campaign per day. This helps control your campaign's costs"
                                    >Daily Budget ( minutes : Max 100) </label>
                                    <input
                                        className="input"
                                        type="number"
                                        name="dailyBudget"
                                        value={data.dailyBudget}
                                        placeholder='Enter Budget'
                                        onChange={handleChange}
                                        min={0}
                                        maxLength={100}
                                        required
                                    />
                                    <Tooltip id="my-tooltip" place="top-start" clickable />
                                </div>
                            </div>
                        </div>

                        {
                            location.pathname === "/agent/customize-agent" ?
                                agent.agentType === "outbound" ?
                                    <>
                                        <div className="uploader">
                                            <div className="uploader-icon">
                                                {loader.upload ?
                                                    <FaSpinner className='spin' /> :
                                                    <FiUpload />
                                                }
                                            </div>
                                            <div className="uploader-txt">
                                                {data.url ? data.url.split('/')[data.url.split('/').length - 1] :
                                                    <>
                                                        <h6

                                                        >
                                                            Upload File
                                                        </h6>
                                                        <p>Click here to upload a file from where we'll extract the information automatically.</p>
                                                    </>
                                                }
                                            </div>
                                            {loader.upload ? null :
                                                <input
                                                    type="file"
                                                    onChange={uploadFile}
                                                    accept='text/csv'
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Upload your contact list for this campaign. The AI agent will use this list to make outbound calls or send messages during the campaign"
                                                />
                                            }
                                            <Tooltip id="my-tooltip" place="top-start" clickable />
                                        </div>

                                        <div class="alert alert-warning mt-2 p-3" role="alert">
                                            Download file format of the contacts. <a target='_blank' href={excelFile}>Click</a>
                                        </div>
                                    </>
                                    : <></>

                                :
                                data.campaignType === "inbound" ? null :
                                    <>
                                        <div className="uploader">
                                            <div className="uploader-icon">
                                                {loader.upload ?
                                                    <FaSpinner className='spin' /> :
                                                    <FiUpload />
                                                }

                                            </div>
                                            <div className="uploader-txt">
                                                {data.url ? data.url.split('/')[data.url.split('/').length - 1] :
                                                    <>
                                                        <h6

                                                        >Upload File</h6>
                                                        <p>Click here to upload a file from where we'll extract the information automatically.</p>
                                                    </>
                                                }
                                            </div>
                                            {loader.upload ? null :
                                                <input
                                                    type="file"
                                                    onChange={uploadFile}
                                                    accept='text/csv'
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Upload your contact list for this campaign. The AI agent will use this list to make outbound calls or send messages during the campaign"
                                                />

                                            }
                                            <Tooltip id="my-tooltip" place="top-start" clickable />
                                        </div>
                                        <div class="alert alert-warning mt-2 p-3" role="alert">
                                            Download file format of the contacts. <a target='_blank' href={excelFile}>Download</a>
                                        </div>
                                    </>
                        }

                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.save ? <>Saving <FaSpinner className='spin' /></> : "Save Changes"}</span></button>
                    </div>
                    <Tooltip id="my-tooltip" place="top-start" clickable />
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default CreateCampaignModal