import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Rectangle,
    ResponsiveContainer
} from "recharts";
import { fetchWorkflowAll } from "../../Redux/Actions/AgentActions";
import { FaSpinner } from "react-icons/fa";
import { onFetchOverView } from "../../Redux/Actions/CampaignActions";

const CampaignAnalytics = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.campaign)
    const agent = useSelector(state => state.agent.data)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [chartData, setChartData] = useState([])
    const [barChart, setBarchart] = useState([])
    useEffect(() => {
        if (campaign.overviewData.chartData.length > 0) {
            const data = [
                { name: "Call Completed", value: campaign.overviewData.chartData[0].completeRate, color: "#baedbd" },
                { name: "No Response", value: campaign.overviewData.chartData[0].responseRate, color: "#1c1c1c" },
                { name: "Call Hungup", value: campaign.overviewData.chartData[0].hungupRate, color: "#95a4fc" }
            ];
            setChartData(data)
        }
    }, [campaign.overviewData])

    const onFetchWorkflowAll = () => {
        let obj = { agentId: agent.id }
        dispatch(fetchWorkflowAll(obj, loader, setLoader))
    }

    useEffect(() => {
        if (agent && !agent.workflow) {
            onFetchWorkflowAll()
        }
    }, [agent])

    const fetchOverView = () => {
        let data = { campaignId: campaign.data.id }
        dispatch(onFetchOverView(data, setBarchart, loader, setLoader, "chart"))
    }

    useEffect(() => {
        if (campaign.data) {
            fetchOverView()
        }
    }, [campaign.data])

    return (
        <>
            <div className="account-block">
                <h6>Analytics</h6>
                <div className="campaign-info">
                    <ul className="justify-content-start gap-5">
                        <li>
                            <div className="campaign-info-single">
                                <p>Call pickup rate</p>
                                <h6>{campaign.overviewData.pickupRate}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Average call duration</p>
                                <h6>{campaign.overviewData.avgCallDuration}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Positive Customer Sentiment</p>
                                <h6>N/A</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Task completion Rate</p>
                                <h6>N/A</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="campaign-stat">
                <div className="campaign-stat-left">
                    <div className="account-block">
                        <h6>Campaign Status</h6>
                        <PieChart width={250} height={200} style={{ height: "100%", width: "100%" }}>
                            <Pie
                                data={chartData}
                                // cx={50}
                                // cy={200}

                                innerRadius={50}
                                outerRadius={80}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                            >
                                {chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>

                        </PieChart>
                        <div className="px-3">
                            {chartData.length > 0 ?
                                chartData.map((curElem, index) => {
                                    return (
                                        <div className="d-flex justify-content-between align-items-center" key={index}>
                                            <span className={`tStatus `} style={{ color: curElem.color }}>{curElem.name}</span>
                                            <span>{curElem.value}%</span>
                                        </div>
                                    )
                                })
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="campaign-stat-right">
                    <div className="account-block">
                        <h6>Task Status</h6>
                        <div className="table-responsive mt-3">
                            <table className="table theme-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loader.fetch && agent.workflow?.length > 0 && agent.workflow[0].type !== "" ?
                                        agent.workflow.map((curElem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{curElem.type}</td>
                                                    <td><span className="tStatus progress">In Progress</span></td>
                                                    <td><span>View</span></td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                {loader.fetch ?
                                                    <FaSpinner className="spin" />
                                                    : "No workflow assigned to the agent."
                                                }
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >

            <div className="account-block mt-4">
                <h6>Call Count Overview</h6>
                <BarChart
                    width={1000}
                    height={300}
                    style={{ height: "100%", width: "100%", padding: 20 }}
                    data={barChart}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                        dataKey="call"
                        fill="#a8c5da"
                        activeBar={<Rectangle fill="black" />}
                    />
                </BarChart>
            </div>

        </>
    )
}
export default CampaignAnalytics;