import React from 'react'

const WebhookBlank = ({ handleShow }) => {
    return (
        <div className="blank-container">
            <div className="blank-container-in">
                <h5 className="fw-700 pb-3">No Webhooks Created Yet</h5>
                <img src={require("../../images/agent.svg").default} alt="" />
                <hr />
                <h5 className="fw-500 pb-3">You need three steps to add a webhook</h5>

                <div className="cta mb-3">
                    <div className="cta-icon"><img src={require("../../images/icon/rocket.svg").default} alt="" /></div>
                    <div className="cta-txt">
                        <h6>Webhook Action</h6>
                        <p>Selection the webhook action you need</p>
                    </div>
                </div>

                <div className="cta mb-3">
                    <div className="cta-icon"><img src={require("../../images/icon/clock2.svg").default} alt="" /></div>
                    <div className="cta-txt">
                        <h6>Webhook Url</h6>
                        <p>Place the webhook url you want to use</p>
                    </div>
                </div>

                <div className="cta mb-3">
                    <div className="cta-icon"><img src={require("../../images/icon/add.svg").default} alt="" /></div>
                    <div className="cta-txt">
                        <h6>Bearer Token</h6>
                        <p>Generate the bearer token for your webhook to use</p>
                    </div>
                </div>

                <button className="theme-btn mt-2" onClick={handleShow}>+ Create  Webhook</button>
            </div>
        </div>
    )
}

export default WebhookBlank